import { computed } from "vue"

import type { Profile } from "@/modules/profile"

export const useProfileSubscription = (profile: Profile | undefined) => {
  return computed(() => {
    if (profile) {
      if (profile?.subscription === "FREE") {
        return true
      } else {
        const daysLeft = profile?.days_left

        if (daysLeft === undefined || daysLeft === null) {
          return true
        }
      }
    }
    return false
  })
}
