<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { getCurrentInstance, ref, watch } from "vue"
import { TransitionFade } from "@morev/vue-transitions"
import { noop } from "@vueuse/core"

import { gridGutter } from "@/package/ui-kit"
import { useBankAutocomplete } from "@/modules/bank-account"
import { useForm, useValidateInfoWithFieldMessages } from "@/package/hooks"
import { isValidateError } from "@/package/util"

import { RegistrantType } from "../interfaces"
import { useRuleset } from "../hooks/use-ruleset"
import { useFormState } from "../hooks/use-form-state"
import { useRegister } from "../hooks/use-register"
import { useValidatePromocode } from "../hooks/use-check-promo"

const props = defineProps({
  promoCode: {
    type: String,
    default: () => undefined
  }
})

const emit = defineEmits(["success"])

const personType = ref(RegistrantType.PhysicalEntity)
const emptyPatronymic = ref(false)
const hasPromocode = ref(false)
const showPassword = ref(false)
const { formState, physicalEntity, legalEntity } = useFormState()
const { registerRequest, isRegisterLoading, registerError, fieldErrors, cleanFieldError } =
  useRegister(personType, physicalEntity, legalEntity)
const rules = useRuleset(formState, emptyPatronymic, personType)

const {
  validateInfos: ValidateInfos,
  validate,
  clearValidate
} = useForm(formState, rules, {
  watchValidateOptions: { validateFirst: true }
})
const validateInfos = useValidateInfoWithFieldMessages(ValidateInfos, fieldErrors)
const { bankDataRequestErrorMessage } = useBankAutocomplete(formState, validate)
const { validatePromocode, isValidating, validationError } = useValidatePromocode()
const formRef = ref<typeof Form | null>(null)

const instance = getCurrentInstance()

watch(emptyPatronymic, (val) => {
  if (val) {
    formState.middle_name = null
    clearValidate("middle_name")
  }
})

watch(
  () => props.promoCode,
  (promoCode) => {
    if (promoCode) {
      formState.promocode = promoCode
      hasPromocode.value = true
    }
  },
  { immediate: true }
)
const onFinish = () =>
  validate()
    .then(() => registerRequest(() => emit("success")))
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

watch(bankDataRequestErrorMessage, (err) => {
  if (err) {
    validateInfos.value.bic.help = [err]
    validateInfos.value.bic.validateStatus = "error"

    instance?.proxy?.$forceUpdate()
  }
})

const validatePromo = async () => {
  await validatePromocode(formState.promocode as string)
  validateInfos.value.promocode.help = ["Промокод активирован"]
  validateInfos.value.promocode.validateStatus = "success"

  instance?.proxy?.$forceUpdate()
}

watch(validationError, (err) => {
  if (err) {
    validateInfos.value.promocode.help = [err.message]
    validateInfos.value.promocode.validateStatus = "error"

    instance?.proxy?.$forceUpdate()
  }
})
</script>

<template>
  <AFlex vertical>
    <TransitionFade>
      <template v-if="registerError">
        <AAffix :offset-top="12">
          <AFlex :gap="8" vertical :style="{ width: '100%' }">
            <AAlert
              v-if="registerError"
              type="error"
              class="shake"
              :message="registerError"
              closable
              show-icon
            />
          </AFlex>
        </AAffix>
      </template>
    </TransitionFade>
    <AForm
      ref="formRef"
      v-enter-to-tab
      name="register"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      hide-required-mark
      :style="{ marginTop: '12px' }"
      scroll-to-first-error
      @submit.prevent="onFinish"
    >
      <ARow :gutter="gridGutter">
        <ACol :xs="24" :xxl="12">
          <AFormItem label="Фамилия" name="last_name" v-bind="validateInfos?.last_name">
            <AInput v-model.trim="formState.last_name" size="large" placeholder="Введите фамилию" />
          </AFormItem>
        </ACol>
        <ACol :xs="24" :xxl="12">
          <AFormItem label="Имя" name="first_name" v-bind="validateInfos?.first_name">
            <AInput v-model.trim="formState.first_name" size="large" placeholder="Введите имя" />
          </AFormItem>
        </ACol>
      </ARow>

      <AFormItem label="Отчество" name="middle_name" v-bind="validateInfos?.middle_name">
        <ARow :gutter="gridGutter" :wrap="false">
          <ACol :xxl="12" flex="auto">
            <AInput
              v-model.trim="formState.middle_name"
              size="large"
              placeholder="Введите отчество"
              :disabled="emptyPatronymic"
            />
          </ACol>
          <ACol
            :xxl="12"
            :style="{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }"
          >
            <AFormItemRest>
              <ACheckbox v-model:checked="emptyPatronymic" :style="{ whiteSpace: 'nowrap' }">
                Нет отчества
              </ACheckbox>
            </AFormItemRest>
          </ACol>
        </ARow>
      </AFormItem>

      <ARow :gutter="gridGutter">
        <ACol :xs="24" :sm="12" :lg="24" :xxl="12">
          <AFormItem label="E-mail" name="email" v-bind="validateInfos?.email">
            <AInput
              v-model.lowercase.trim="formState.email"
              size="large"
              placeholder="Введите e-mail"
              @change="
                () => {
                  cleanFieldError('email')
                  if (validateInfos?.email.validateStatus === 'error') {
                    validate('email', { trigger: 'blur' }).catch(noop)
                  }
                }
              "
              @blur="() => validate('email', { trigger: 'blur' }).catch(noop)"
            />
          </AFormItem>
        </ACol>
        <ACol :xs="24" :sm="12" :lg="24" :xxl="12">
          <AFormItem name="phone" v-bind="validateInfos?.phone" label="Номер телефона">
            <APhoneInput
              v-model="formState.phone"
              size="large"
              @change="() => cleanFieldError('phone')"
              @blur="() => validate('phone', { trigger: 'blur' }).catch(noop)"
            />
          </AFormItem>
        </ACol>
      </ARow>

      <ARow :gutter="gridGutter">
        <ACol :xs="24" :sm="12" :lg="24" :xxl="12">
          <AFormItem label="Пароль" name="password" v-bind="validateInfos?.password">
            <AInputPassword
              v-model:visible="showPassword"
              v-model:value="formState.password"
              autocomplete="new-password"
              size="large"
              placeholder="Введите пароль"
              @blur="() => validate('password', { trigger: 'blur' }).catch(noop)"
            />
          </AFormItem>
        </ACol>
        <ACol :xs="24" :sm="12" :lg="24" :xxl="12">
          <AFormItem
            label="Подтверждение пароля"
            name="password_confirmation"
            v-bind="validateInfos?.password_confirmation"
          >
            <AInputPassword
              v-model:value="formState.password_confirmation"
              v-model:visible="showPassword"
              size="large"
              :visibility-toggle="false"
              placeholder="Повторите пароль"
            />
          </AFormItem>
        </ACol>
      </ARow>

      <AFormItem :style="{ marginBottom: '24px' }">
        <ACheckbox v-model:checked="hasPromocode" :style="{ whiteSpace: 'nowrap' }">
          У меня есть промокод
        </ACheckbox>
      </AFormItem>

      <ARow v-if="hasPromocode" :gutter="gridGutter">
        <ACol :xs="24" :sm="12" :lg="24" :xxl="12">
          <AFormItem label="Промокод" name="promo_code" v-bind="validateInfos?.promocode">
            <AInput v-model="formState.promocode" size="large" placeholder="Введите промокод" />
          </AFormItem>
        </ACol>
        <ACol :xs="24" :sm="12" :lg="24" :xxl="12">
          <AFormItem>
            <AButton
              :disabled="!formState.promocode"
              type="default"
              block
              size="large"
              :loading="isValidating"
              :style="{ marginTop: '22px' }"
              @click="validatePromo"
              >Применить промокод
            </AButton>
          </AFormItem>
        </ACol>
      </ARow>

      <AFormItem :style="{ marginTop: '24px' }" name="accept" v-bind="validateInfos?.accept">
        <ACheckbox v-model:checked="formState.accept">
          <ATypographyText>
            Я принимаю
            <ATypographyLink href="/docs/SAMO_UserGuide.pdf" target="_blank"
              >Правила пользования сервисом
            </ATypographyLink>
            {{ " " }}и{{ " " }}
            <ATypographyLink href="/docs/SAMO_privacy_policy.pdf" target="_blank">
              Политику конфиденциальности
            </ATypographyLink>
          </ATypographyText>
        </ACheckbox>
      </AFormItem>

      <AButton
        type="primary"
        html-type="submit"
        block
        size="large"
        :style="{ marginTop: '4px' }"
        :loading="isRegisterLoading"
        >Продолжить
      </AButton>
    </AForm>
  </AFlex>
</template>
