<script setup lang="ts">
import dayjs from "dayjs"
import { computed, h, reactive, watch, onMounted, onUnmounted } from "vue"
import { LogoutOutlined, CommentOutlined, StarFilled } from "@ant-design/icons-vue"
import { type RouteLocationNormalizedLoaded, useRoute, useRouter } from "vue-router"
import { clearCache } from "vue-request"
import { useDebounceFn } from "@vueuse/core"
import { theme } from "ant-design-vue"

import { cleanAuth } from "@/package/api-client"
import {
  FinanceRouteName,
  loginRoute,
  ProfileRouteName,
  ReferencesRouteName,
  SupportSection,
  Subscription
} from "@/router"
import {
  injectProfile,
  injectProfileStatus,
  ProfileStatus,
  injectProfileReset,
  injectProfileRefresh,
  useProfileSubscription
} from "@/modules/profile"
import { useAdoptable } from "@/package/ui-kit"

import { useMenuItems } from "./use-menu-items"

const profile = injectProfile()
const refreshAsync = injectProfileRefresh()

const closed = defineModel({ type: Boolean })
const state = reactive({
  rootSubmenuKeys: [ProfileRouteName, ReferencesRouteName, FinanceRouteName],
  openKeys: new Array<string>(),
  selectedKeys: new Array<string>()
})
const profileStatus = injectProfileStatus()
let items = useMenuItems(profileStatus)
const isAdoptable = useAdoptable()
const isSelectable = computed(() => profileStatus.value.status !== ProfileStatus.UNCONFIRMED)
const isDisabledPurchase = computed(
  () => profile?.value?.subscription === "PRO" && !profile?.value?.is_trial
)

const onOpenChange = (openKeys: string[]) => {
  state.openKeys = openKeys
}
const onSelect = () => {
  if (isAdoptable.value) {
    closed.value = true
  }
}

const route = useRoute()
const setupRoute = useDebounceFn((route: RouteLocationNormalizedLoaded) => {
  if (typeof route.name === "string" && !state.selectedKeys.includes(route.name)) {
    const routeNames = route.matched
      .map((route) => route.name?.toString())
      .filter(Boolean)
      .reverse() as Array<string>

    state.selectedKeys = state.selectedKeys.filter((selectedKey) =>
      routeNames.includes(selectedKey)
    )
    state.selectedKeys.push(...routeNames)
  }
}, 100)
watch(closed, (closed) => {
  if (isAdoptable.value && !closed) {
    if (profileStatus.value.status === ProfileStatus.ACTIVE) {
      setupRoute(route)
    }
  }
})
watch(
  [profileStatus, route],
  ([profileStatus, route]) => {
    if (!isAdoptable.value) {
      if (profileStatus.status === ProfileStatus.ACTIVE) {
        setupRoute(route)
      }
    }
  },
  { immediate: true }
)

const profileReset = injectProfileReset()
const router = useRouter()
const logoutAction = () => {
  profileReset()
  clearCache()
  cleanAuth()
  router.push({ name: loginRoute })
}

const token = theme.useToken()

watch(
  profile,
  (newValue) => {
    if (newValue) {
      const isSubscriptionExpired = useProfileSubscription(newValue)
      items = useMenuItems(profileStatus, isSubscriptionExpired.value)
    }
  },
  { immediate: true }
)

let timer: ReturnType<typeof setTimeout> | null = null

function executeAtMidnight() {
  const now = dayjs().tz("Europe/Moscow")
  const nextMidnight = now.add(1, "days").startOf("day")
  const timeToMidnight = nextMidnight.diff(now, "seconds") * 1000

  if (timeToMidnight > 0) {
    timer = setTimeout(() => {
      refreshAsync()
    }, timeToMidnight)
  }
}

onMounted(() => {
  executeAtMidnight()
})
onUnmounted(() => {
  if (timer !== null) {
    clearInterval(timer)
    timer = null
  }
})
</script>

<template>
  <AFlex vertical justify="space-between" :style="{ overflowY: 'auto', maxHeight: '100%' }">
    <AMenu
      v-model:selectedKeys="state.selectedKeys"
      mode="inline"
      :open-keys="state.openKeys"
      :items="items"
      theme="dark"
      :selectable="isSelectable"
      @openChange="onOpenChange"
      @select="onSelect"
    />
    <AMenu
      v-model:selectedKeys="state.selectedKeys"
      mode="inline"
      theme="dark"
      :selectable="isSelectable"
      @select="onSelect"
    >
      <AMenuItem
        v-if="
          !isDisabledPurchase &&
          profileStatus.status !== ProfileStatus.UNKNOWN &&
          !profileStatus.asAdmin
        "
        :key="Subscription"
        class="subscription-button"
        :icon="h(StarFilled)"
        :style="{ background: token.token.value.colorPrimary }"
        @click="() => router.push({ name: Subscription })"
        >Оформить подписку</AMenuItem
      >
      <AMenuItem
        :key="SupportSection"
        :icon="h(CommentOutlined)"
        @click="() => router.push({ name: SupportSection })"
        >Техническая поддержка</AMenuItem
      >
      <AMenuItem :key="2" :icon="h(LogoutOutlined)" @click="logoutAction">
        Выход из аккаунта
      </AMenuItem>
    </AMenu>
  </AFlex>
</template>

<style>
.item-disabled {
  pointer-events: none;

  &::after {
    content: "Про";
    position: absolute;
    top: 50%;
    right: 25px;
    height: 18px;
    padding: 0 8px;
    border: 1px solid #0057ff;
    border-radius: 4px;
    color: #0057ff;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    transform: translateY(-50%);
  }
}
</style>
