import "dayjs/locale/ru"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { createApp } from "vue"
import { createPinia } from "pinia"
import { abilitiesPlugin } from "@casl/vue"

import { refreshPost } from "@/modules/auth"
import { setRefresher, hasAuth, onRefreshFailed } from "@/package/api-client"
import { CustomAnt, pushNotificatonsPlugin } from "@/package/ui-kit"
import { AppDefaultAbilities, sentryPlugin } from "@/package/config"

import { useUserAuth, default as router, loginRoute } from "./router"
import App from "./App.vue"

import "./scss/index.scss"

dayjs.locale("ru")
dayjs.extend(utc)
dayjs.extend(timezone)

useUserAuth(hasAuth)
onRefreshFailed(() => router.push({ name: loginRoute }))
setRefresher((refreshToken) =>
  refreshPost(refreshToken)
    .then(({ refresh_token, access_token }) => {
      return {
        token: access_token,
        refresh: refresh_token
      }
    })
    .catch(() => null)
)

if (import.meta.env.VITE_APP_ENV === "production") {
  const script = document.createElement("script")
  script.type = "text/javascript"
  script.async = true
  script.innerHTML = `
    (function(m,e,t,r,i,k,a){
      m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {
        if (document.scripts[j].src === r) { return; }
      }
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    ym(98536613, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
    });
  `
  document.head.appendChild(script)

  const noScript = document.createElement("noscript")
  noScript.innerHTML =
    '<div><img src="https://mc.yandex.ru/watch/98536613" style="position:absolute; left:-9999px;" alt="" /></div>'
  document.head.appendChild(noScript)
}

createApp(App)
  .use(createPinia())
  .use(abilitiesPlugin, AppDefaultAbilities)
  .use(router)
  .use(CustomAnt)
  .use(pushNotificatonsPlugin)
  .use(sentryPlugin, { router })
  .mount("#app")
