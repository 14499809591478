import type { EventBusKey } from "@vueuse/core"
import type { PaymentSubscriptions } from "../interface"

import { useEventBus } from "@vueuse/core"

export type PaymentSubscriptionsEvents = PaymentSubscriptions["event"]
export const paymentSubscriptionsBus: EventBusKey<PaymentSubscriptionsEvents> =
  Symbol("PaymentSubscriptionsBus")
export const usePaymentSubscriptionsBus = () =>
  useEventBus<PaymentSubscriptionsEvents, { message: string }>(paymentSubscriptionsBus)
